body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

footer {
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.reggae-one-regular {
  font-family: "Reggae One", system-ui;
  font-weight: 400;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
  border:0;
}

table td, table th {
    border:0;
    border-bottom: 1px solid #ddd; /* ボトムにだけ枠線を設定 */
    padding: 5px;
}

table th {
  text-align: left;
}

.wid420{
    width:420px;
    margin:0 auto;
}
.wid540{
    width:540px;
    margin:0 auto;
}
.wid670{
    width:670px;
    margin:0 auto;
}
.parent,	/* 親 */
.pos-rel{	/* 親 */
    position: relative;
}
.child,	/* 子 */
.pos-abs{	/* 子 */
    position: absolute;
    top:0;
    left:0;
}
.child-center{	/* 子 */
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}
.pc-only{
    display:inline !important;
}
.sp-only{
    display:none !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1080deg); /* 360度 x 3回転 */
  }
  100% {
    transform: rotate(1080deg); /* 最終的に止まった状態 */
  }
}

img.rotate{
  display: block;
  margin: 0 auto;
  animation: spin 6s ease-in-out infinite;
}
.box{
    background-color:#eae5e3;
    margin-bottom:10px;
    clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 20px);
}
.bg9790a4{
    background-color:#493759;
    color:#fff;
}
.bgeae5e3{
    background-color:#eae5e3;
}
.bg9790a4-a{
    background-color:rgba(73,55,89,0.7);
    color:#fff;
}

h1 {
    font-size: 2.0rem;
}

h2 {
    font-size: 2.0rem;
    background-color: #493759;
    padding: 10px 10px 10px 30px;
    color:#fff !important;
    clip-path: polygon(14px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%);
    font-family: "Reggae One", system-ui;
}

h3{
    padding:5px;
    border-bottom:10px solid #9790a4;
}
h4{
    padding:10px;
    clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 20px);
}
h5{
    font-size:16px;
    padding-left:5px;
    border-left:10px solid #9790a4;
    font-weight:bold;
}
.btn-primary{
    background-color:#493759;
    border:0;
    width:100%;
    border-radius:0;
}
.text-underline-custom {
    text-decoration: underline; /* 下線を追加 */
    text-decoration-color: #eae5e3; /* 下線の色を赤に変更 */
    text-decoration-thickness: 3px; /* 下線の太さを指定 */
}
.clip-custom {
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
/*    clip-path: polygon(0 0, 85% 0, 100% 100%, 0 100%);*/
}
.menu-link,
.nav-link{
    cursor: pointer;
}
.top-button {
  position: fixed;
  bottom: 100px;
  right: 0px;
  background-color: rgba(234,229,227,0.7);
  color: #444;
  padding: 10px 15px;
}
.top-button a{
    color:#444;
}
.table-center {
  margin: 0 auto; /* テーブルを中央に配置 */
  text-align: left; /* テキストは左寄せ */
  word-break: keep-all; /* 単語の途中での改行を防ぐ */
}
#footer-data a{
    color:#444;
}
.fs-1-2 {
  font-size: 1.2rem; /* お好みのサイズに変更 */
}
.ib{
    display:inline-block !important;
    width:auto;
}
#canvas {
    height:480px !important;
    background-color:#316745;
    touch-action: none;
}

.modelchild{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:100;
    color:#fff;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin2 2s linear infinite;
}

@keyframes spin2 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media screen and (max-width: 768px) {
    .pc-only{
	display:none !important;
    }
    .sp-only{
	display:inline !important;
    }
    .wid420,
    .wid540,
    .wid670{
	width:100%;
    }
    h1{
	padding:0 10px;
	font-size:1.8em;
    }
    h2{
	margin-top:10px;
	text-align:left !important;
	clip-path: none;
	font-size:1.6em !important;
	text-align:center;
    }
    .row{
	margin-left:-5px;
	margin-right:-5px;
    }
    .container{
	padding:5px;
    }
    .p-4{
    }
    .px-4{
    }
    .clip-custom {
	clip-path: none;
    }
    .table-center{
	word-break: normal;
    }
    table{
	width:100%;
    }
}